<template>

<div class="head" :class="{'is-scrolling': y > 0}">

	<div class="head-inner">

		<router-link :to="{name: 'Home'}" class="head-logo">cardboard<span>events</span></router-link>

		<div class="head-toggle" :class="{'is-active': toggle}" v-if="window.is.mobile" v-on:click="toggle = !toggle">Menu</div>

		<div class="head-menu" v-if="toggle || !window.is.mobile">

			<router-link :to="{name: 'Features'}" class="head-menu-item">Discover</router-link>
			<router-link :to="{name: 'Pricing'}" class="head-menu-item">Pricing</router-link>
			<router-link :to="{name: 'Contact'}" class="head-menu-item">Contact</router-link>
			<a :href="login" target="_blank" class="head-menu-item is-button is-white">Log in</a>
			<router-link :to="{name: 'Signup'}" class="head-menu-item is-button">Get started</router-link>

		</div>

	</div>

</div>

</template>

<script>

export default {

	data: function() {

		return {
			toggle: false,
			y: 0,
			login: process.env.VUE_APP_LOGIN
		}

	},

	watch: {

		$path: function() {

			window.scrollTo(0, 0)
			this.toggle = false

		}

	},

	created: function() {

		window.addEventListener('scroll', this.onScroll.bind(this));

	},

	beforeDestroy: function() {

		window.removeEventListener('scroll', this.onScroll.bind(this));

	},

	methods: {

		onScroll: function() {

			this.y = window.pageYOffset

		}

	}

}

</script>

<style>

.head {
	width: 100%;
	height: 80px;
	z-index: 20;
	position: fixed;
	left: 0px;
	top: 0px;
	transition: all 100ms linear;
}

.is-mobile .head{
	height: 48px;
}

.head.is-scrolling {
	background-color: #1e3054;
}

.head-inner {
	width: 100%;
	margin: 0px auto;
	max-width: 1000px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	height: 80px;
}

.is-mobile .head-inner {
	height: 48px;
}

.head-logo {
	line-height: 80px;
	font-size: 28px;
	color: #fff;
	font-weight: 400;
	background-image: url(~@/assets/cell.svg);
	background-size: 32px auto;
	background-repeat: no-repeat;
	background-position: 0px 50%;
	padding-left: 40px;
}

.is-mobile .head-logo {
	line-height: 48px;
	font-size: 20px;
	background-size: 24px auto;
	background-position: 10px 50%;
	z-index: 2;
}

.head-logo i {
	margin-right: 10px;
}

.head-logo span {
	color: #ffbe7a;
}

.head-toggle {
	color: #fff;
	font-size: 14px;
	font-weight: 400;
	height: 32px;
	border: 1px solid #fff;
	border-radius: 8px;
	margin-right: 10px;
	line-height: 32px;
	text-transform: uppercase;
	padding: 0px 10px;
	z-index: 2;
}

.head-toggle.is-active {
	background-color: #fff;
	color: #1e3054;
}

.head-menu {
	display: flex;
	justify-content: flex-end;
	height: 80px;
	align-items: center;
}

.is-mobile .head-menu {
	position: fixed;
	top: 0px;
	padding: 68px 0px 0px 0px;
	height: calc(100vh - var(--vh-offset, 0px));
	left: 0px;
	right: 0px;
	justify-content: flex-start;
	flex-direction: column;
	background-color: #1e3054;
	z-index: 1;
}

.head-menu-item {
	text-transform: uppercase;
	color: #fff;
	font-size: 14px;
	letter-spacing: 1px;
	font-weight: 400;
	margin: 0px 10px;
	transition: transform 100ms linear;
}

.is-mobile .head-menu-item {
	margin: 10px 0px;
}

.head-menu-item:not(.is-button):hover {
	text-decoration: underline;
}

.is-desktop .head-menu-item.is-button:hover {
	transform: scale(1.05);
}

.head-menu-item.is-button {
	padding: 10px;
	background-color: #ffbe7a;
	border-radius: 4px;
	font-weight: 600;
    color: #333;
}

.is-mobile .head-menu-item.is-button {
	width: 160px;
	text-align: center;
}

.head-menu-item.is-button.is-white {
	background-color: #fff;
    color: #333;
	margin-left: 20px;
}

.is-mobile .head-menu-item.is-button.is-white {
	margin-left: 0px;
	margin-top: 40px;
}

</style>
